.t-element {
    height: 5rem;
    width: 5rem;
    background-color: red;
}

.nav-link {
    opacity: .5;
}

.nav-link:hover > .nav-line {
    width: 40px;
    transition: .3s ease-in-out;
}


.description p span.highlight {
  color: #fff;
  animation: 5s ease-in-out 0s 1 flash;
}

.description p span.highlight.two {
  animation: 5s ease-in-out 0s 1 flashTwo;
}



@keyframes flash {
  0% {
    color: #777778;
  }

  20% {
    color: #cecece;
  }

  30% {
    color: #ffffff;
  }

  40% {
    color: #b4b4b4;
  }

  45% {
    color: #777778;
  }

  100% {
    color: #fff;
  }
}

@keyframes flashTwo {
  0% {
    color: #cecece;
  }

  20% {
    color: #fff;
  }

  30% {
    color: #777778;
  }

  40% {
    color: #cecece;
  }

  45% {
    color: #777778;
  }

  100% {
    color: #fff;
  }
}

