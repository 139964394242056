
/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap'); */

/*===== IMPORTING FONTS =====*/

@font-face {
  font-family: 'air-regular';
  src: url('./assets/fonts/AirbnbCereal-Book.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}  

@font-face {
  font-family: 'air-semibold';
  src: url('./assets/fonts/AirbnbCereal-Medium.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

@font-face {
  font-family: 'air-light';
  src: url('./assets/fonts/AirbnbCereal-Light.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

@font-face {
  font-family: 'air-bold';
  src: url('./assets/fonts/AirbnbCereal-Bold.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

@font-face {
  font-family: 'air-extrabold';
  src: url('./assets/fonts/AirbnbCereal-ExtraBold.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

@font-face {
  font-family: 'air-black';
  src: url('./assets/fonts/AirbnbCereal-Black.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

@tailwind base;
@tailwind components;
@tailwind utilities;



.main::-webkit-scrollbar {
  width: 7px;
}


.main::-webkit-scrollbar-track {
  background: #101011; 
}
 

.main::-webkit-scrollbar-thumb {
  background:#101011; 
}

.main::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'air-light';
}

code {
  font-family: 'air-light'; 
}

.display-none {
  @apply hidden;
}


.description p span.highlight {
  color: #fff;
  animation: 5s ease-in-out 0s 1 flash;
}

.description p span.highlight.two {
  animation: 5s ease-in-out 0s 1 flashTwo;
}

.description-dark p span.highlight {
  color: #000;
  animation: 5s ease-in-out 0s 1 flashDark;
}



@keyframes flash {
  0% {
    color: #777778;
  }

  20% {
    color: #cecece;
  }

  30% {
    color: #ffffff;
  }

  40% {
    color: #b4b4b4;
  }

  45% {
    color: #777778;
  }

  100% {
    color: #fff;
  }
}

@keyframes flashTwo {
  0% {
    color: #cecece;
  }

  20% {
    color: #fff;
  }

  30% {
    color: #777778;
  }

  40% {
    color: #cecece;
  }

  45% {
    color: #777778;
  }

  100% {
    color: #fff;
  }
}

@keyframes flashDark {
   0% {
    color: #333; 
  }

  20% {
    color: #777; 
  }

  30% {
    color: #555; 
  }

  40% {
    color: #999;
  }

  45% {
    color: #bbb; 
  }

  100% {
    color: #000
  }
}